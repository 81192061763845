/* eslint-disable no-useless-escape */
/* eslint-disable */
// import { SET_THEME } from "@/core/services/store/main.module";

require("dotenv").config();
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import "./registerServiceWorker";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@/core/plugins/CKEditor";
import "@/core/plugins/VueTheMask";
import "@/core/plugins/tree-view";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/vuetify-datetimePicker";
import "@/core/plugins/vue-ui-preloader";
import vuetify from "@/core/plugins/vuetify";
import i18nService from "@/core/services/i18n.service";

// API service init
ApiService.init();

// Default routes that do not require authentication
const defaultRoutes = [
  { name: "login", path: "/login" },
  { name: "loginNew", path: "/integration/login" },
  { name: "register", path: "/register" },
  { name: "registerNew", path: "/integration/register" },
  { name: "logout", path: "/logout" },
  { name: "forgot_password", path: "/forgot_password" },
  { name: "password_reset", path: "/passwrod_reset" },
  { name: "invoiceTemp", path: "/invoice_temp" },
  { name: "maintenance", path: "/maintenance" },
  { name: "privacy_policy", path: "/privacy_policy" },
  { name: "terms_and_conditions", path: "/terms_and_conditions" },
];

/**
 * Check if the route does not require authentication.
 * @param {Object} to - The target route object.
 * @returns {boolean} True if the route does not require authentication, false otherwise.
 */
function isRouteDoNotRequireAuthentication(to) {
  return defaultRoutes.some((route) => route.name === to.name);
}

/**
 * Middleware to check if the user is authenticated.
 * Redirects to login page if not authenticated.
 * @param {Function} next - The next function to proceed with navigation.
 */
function isAuthenticatedMiddleware(next) {
  if (!store?.getters?.isAuthenticated) {
    next({ name: "login" });
  }
}

/**
 * Apply theme based on the user's theme settings.
 * @param {Object} response - The response object containing user data.
 */
function applyTheme(response) {
  const theme = response?.data?.theme;
  if (theme && store?.getters?.getHasThemeSet < 2) {
    const style = document.documentElement.style;
    for (const key in theme) {
      style.setProperty("--iq-" + key, theme[key]);
    }
  }
}

/**
 * Check if translations need to be updated and fetch if necessary.
 * @param {Object} response - The response object containing user data.
 */
async function checkTranslationData(response) {
  if (
    response?.data?.translations_last_updated_time >
    parseInt(localStorage.getItem("last_updated_time"))
  ) {
    await i18nService.fetchLanguageDataFromServer();
    i18nService.setActiveLanguage(i18nService.getActiveLanguage());
  }
}

/**
 * Check if the user has permission for the route.
 * @param {Object} to - The target route object.
 * @param {Function} next - The next function to proceed with navigation.
 */
function checkPermissionForRoute(to, next) {
  const currentUser = store?.getters?.currentUser;
  if (currentUser?.permissions.includes(to.name)) {
    next();
  } else {
    next({ name: currentUser.permissions[0] });
  }
}

/**
 * Verify user authentication and handle authorization for the route.
 * @param {Object} to - The target route object.
 * @param {Function} next - The next function to proceed with navigation.
 */
async function verifyUserAuth(to, next) {
  try {
    const response = await store.dispatch(VERIFY_AUTH);
    if (response?.status > 299 || response?.data?.data?.status !== "Active") {
      next({ name: "login" });
      return;
    }
    applyTheme(response);
    await checkTranslationData(response);
  } catch (error) {
    next({ name: "login" });
  }
}

// Navigation guard
router.beforeEach(async (to, from, next) => {
  // console.log(from.path, to.path);
  // If the route does not require authentication, proceed
  if (isRouteDoNotRequireAuthentication(to)) {
    next();
  } else {
    store.commit(SET_PAGE_LOADING, true);
    isAuthenticatedMiddleware(next);
    await verifyUserAuth(to, next);
    // Reset filters and scroll page to top
    checkPermissionForRoute(to, next);
    store.commit(SET_FILTERS_DATA, {});
    setTimeout(() => {
      store.commit(SET_PAGE_LOADING, false);
      window.scrollTo(0, 0);
    }, 100);
  }
});

// Vue app initialization
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
