import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "getCONTACTSTableStructure";
export const UPDATE_TABLE_DATA = "getCONTACTSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setCONTACTSTableStructure";
export const SET_TABLE_DATA = "setCONTACTSTableData";
export const SET_TABLE_FILTER = "setCONTACTSTableFilter";
export const SET_TABLE_PAGE = "setCONTACTSTablePage";
export const SET_TABLE_PERPAGE = "setCONTACTSTablePerPage";
export const SET_TABLE_SORTBY = "setCONTACTSTableSortBy";
export const SET_TABLE_SORTORDER = "setCONTACTSTableSortOrder";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getCONTACTSTableState(state) {
    return state.table_state;
  },

  getCONTACTSTableHeaders(state) {
    let supHeader = [...state.table_structure.columns];
    const valuesToRemove = [
      "twitter",
      "linkedin",
      "instagram",
      "facebook",
      "youtube",
      "pinterest",
    ];
    let filteredHeader = supHeader.filter((header) => {
      if (!valuesToRemove.includes(header.value)) return true;
      return false;
    });
    const updated_at = filteredHeader.pop();
    filteredHeader.push({
      align: "left",
      class:
        "datatable-cell datatable-cell-sort datatable-cell-sorted colHeader font-size-lg primary--text",
      export_order: 1,
      exportable: false,
      itemClass: "inText2",
      mobile_visible: true,
      sortable: false,
      text: "Social Media",
      type: "social_link",
      value: "social_link",
      visible: true,
      width: "220px",
    });
    filteredHeader.push(updated_at);
    return filteredHeader;
  },

  getCONTACTSTableFilters(state) {
    return state.table_structure.filters;
  },

  getCONTACTSTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getCONTACTSTableData(state) {
    if (state.table_data.data) {
      const data = state.table_data.data.data;
      let newDataArr = [];
      data.map((item) => {
        const {
          twitter,
          linkedin,
          instagram,
          facebook,
          youtube,
          pinterest,
          ...rest
        } = item;
        newDataArr.push({
          ...rest,
          social_link: {
            twitter,
            linkedin,
            instagram,
            facebook,
            youtube,
            pinterest,
          },
        });
      });
      state.table_data.data.data = newDataArr;
    }
    return state.table_data.data;
  },

  getCONTACTSTableProperties(state) {
    return state.table_structure.properties;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/contacts").then(({ data }) => {
        context.commit(SET_TABLE_STRUCTURE, data);
        resolve(data);
      });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/contacts/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
