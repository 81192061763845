import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

//actions
export const UPDATE_COUNTER_CHART_TYPES =
  "updateWAREHOUSEDASHBOARDCounterChartsTypes";

// mutations
export const SET_COUNTER_CHART_TYPES =
  "setWAREHOUSEDASHBOARDCounterChartsTypes";
export const SET_DASHBOARD_FILTERS = "setWAREHOUSEDASHBOARDFilters";
export const SET_DASHBOARD_URL = "setWAREHOUSEDASHBOARDUrl";
export const SET_FILTER_BUTTON_DISABLED =
  "setWAREHOUSEDASHBOARDSetFilterDisabled";

const state = {
  charts: [],
  filters: [],
  url: null,
  filter_disabled: false,
};

const getters = {
  getWAREHOUSEDASHBOARDCharts: function (state) {
    return state.charts;
  },
  getWAREHOUSEDASHBOARDFilters: function (state) {
    return state.filters;
  },
  getWAREHOUSEDASHBOARDFilterDisabled: function (state) {
    return state.filter_disabled;
  },
};

const actions = {
  async [UPDATE_COUNTER_CHART_TYPES](context, payload) {
    try {
      const { data } = await ApiService.post(context.state.url, payload);
      context.commit(SET_COUNTER_CHART_TYPES, data.charts);
      context.commit(SET_DASHBOARD_FILTERS, data.filters);
    } finally {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      context.commit(SET_PAGE_LOADING, false);
    }
  },
};

const mutations = {
  [SET_COUNTER_CHART_TYPES](state, charts) {
    state.charts = charts;
  },
  [SET_DASHBOARD_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_DASHBOARD_URL](state, url) {
    state.url = url;
  },
  [SET_FILTER_BUTTON_DISABLED](state, value) {
    state.filter_disabled = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
