import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "getSHIPPINGMETHODSTableStructure";
export const UPDATE_TABLE_DATA = "getSHIPPINGMETHODSTableData";
export const EXPORT_TABLE_DATA = "getSHIPPINGMETHODSEXPORTTABLEDATA";

// mutation types
export const SET_TABLE_STRUCTURE = "setSHIPPINGMETHODSTableStructure";
export const SET_TABLE_DATA = "setSHIPPINGMETHODSTableData";
export const SET_TABLE_FILTER = "setSHIPPINGMETHODSTableFilter";
export const SET_TABLE_PAGE = "setSHIPPINGMETHODSTablePage";
export const SET_TABLE_PERPAGE = "setSHIPPINGMETHODSTablePerPage";
export const SET_TABLE_SORTBY = "setSHIPPINGMETHODSTableSortBy";
export const SET_TABLE_SORTORDER = "setSHIPPINGMETHODSTableSortOrder";
export const SET_FILTERS_DATA = "setSHIPPINGMETHODSFiltersData";
export const SET_EXPORT_LINK = "setSHIPPINGMETHODSExportLink";

const state = {
  table_structure: {},
  create_data: {},
  table_data: {},
  filters_data: [],
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getSHIPPINGMETHODSTableState(state) {
    return state.table_state;
  },

  getSHIPPINGMETHODSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getSHIPPINGMETHODSTableFilters(state) {
    return state.table_structure.filters;
  },

  getSHIPPINGMETHODSTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getSHIPPINGMETHODSTableData(state) {
    return state.table_data.data;
  },

  getSHIPPINGMETHODSCreateData(state) {
    return state.create_data;
  },

  getSHIPPINGMETHODSTableProperties(state) {
    return state.table_structure.properties;
  },

  getSHIPPINGMETHODSData(state) {
    return state.table_structure.shipping_methods;
  },

  getSHIPPINGMETHODSExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context, integration_id) {
    return new Promise((resolve) => {
      ApiService.post("validations/shippingMethods", {
        integration_id: integration_id,
      })
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, integration_id, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/validations/shippingMethods/datatable", {
        ...payload,
        integration_id: integration_id,
      })
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/validations/shippingMethods/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_FILTERS_DATA](state, filter_data) {
    state.filters_data = filter_data;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
