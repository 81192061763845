const ID_TOKEN_KEY = "id_token";
// const ID_USER_DATA = "user_data";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

// export const getUser = () => {
//   return JSON.parse(window.localStorage.getItem(ID_USER_DATA));
// };

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

// export const saveUser = (user) => {
//   window.localStorage.setItem(ID_USER_DATA, JSON.stringify(user));
// };

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

// export const destroyUser = () => {
//   window.localStorage.removeItem(ID_USER_DATA);
// };

export default {
  getToken,
  saveToken,
  destroyToken,
  // getUser,
  // saveUser,
  // destroyUser,
};
