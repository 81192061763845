import Vue from "vue";
import VueI18n from "vue-i18n";
import i18nService from "@/core/services/i18n.service";
Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: i18nService.getActiveLanguage(), // set locale
  fallbackLocale: i18nService.defaultLanguage,
  messages: i18nService.loadedLanguageTranslations, // set locale messages
  dateTimeFormats: i18nService.dateTimeFormats,
  numberFormats: i18nService.numberFormats,
});

export default i18n;
