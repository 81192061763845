import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getWESCONFIGCHUTESTableStructure";
export const UPDATE_TABLE_DATA = "getWESCONFIGCHUTESTableData";
export const EXPORT_TABLE_DATA = "exportWESCONFIGCHUTESTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWESCONFIGCHUTESTableStructure";
export const SET_TABLE_DATA = "setWESCONFIGCHUTESTableData";
export const SET_TABLE_FILTER = "setWESCONFIGCHUTESTableFilter";
export const SET_TABLE_PAGE = "setWESCONFIGCHUTESTablePage";
export const SET_TABLE_PERPAGE = "setWESCONFIGCHUTESTablePerPage";
export const SET_TABLE_SORTBY = "setWESCONFIGCHUTESTableSortBy";
export const SET_TABLE_SORTORDER = "setWESCONFIGCHUTESTableSortOrder";
export const SET_EXPORT_LINK = "setWESCONFIGCHUTESExportLink";
export const SET_SORT_PLANS = "setWESCONFIGCHUTESSortPlans";
export const SET_SELECTED_SORT_PLAN = "setWESCONFIGCHUTESSelectedSortPlan";
export const CHUTES_SET_MAPPINGS = "setWESCONFIGCHUTESMappings";
export const CHUTES_SET_LOCATIONS = "setWESCONFIGCHUTESLocations";
export const CHUTES_SET_SELECTED_LOCATION_INDEX =
  "getWESCONFIGCHUTESSelectedLocationIndex";
export const CHUTES_SET_CATEGORIES = "setWESCONFIGCHUTESCategories";
export const CHUTES_ADD_REMOVE_LOCATION_TO_SELECTED_SORT_PLAN =
  "addAndRemoveLocationToSelectedSortPlanWESCONFIGCHUTES";
export const CHUTES_ADD_REMOVE_CATEGORY_TO_SELECTED_LOCATION =
  "addAndRemoveCategoryToSelectedLocationWESCONFIGCHUTES";

const state = {
  sortPlans: [],
  selectedSortPlan: null,
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  mappings: [],
  locations: [],
  selectedLocationIndex: -1,
  categories: [],
};

const getters = {
  getWESCONFIGCHUTESTableProperties(state) {
    return state.table_structure.properties;
  },
  getWESCONFIGCHUTESTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getWESCONFIGCHUTESTableState(state) {
    return state.table_state;
  },

  getWESCONFIGCHUTESTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWESCONFIGCHUTESTableFilters(state) {
    return state.table_structure.filters;
  },

  getWESCONFIGCHUTESTableData(state) {
    return state.table_data.data;
  },

  getWESCONFIGCHUTESExportUrl(state) {
    return state.export_link;
  },
  getWESCONFIGCHUTESSUploadType(state) {
    return state.table_structure.type;
  },
  getWESCONFIGCHUTESSortPlans(state) {
    return state.sortPlans;
  },
  getWESCONFIGCHUTESSelectedSortPlan(state) {
    return state.selectedSortPlan;
  },
  getWESCONFIGCHUTESMappings(state) {
    return state.mappings;
  },
  getWESCONFIGCHUTESLocations(state) {
    return state.locations;
  },
  getWESCONFIGCHUTESSelectedLocationIndex(state) {
    return state.selectedLocationIndex;
  },
  getWESCONFIGCHUTESCategories(state) {
    return state.categories;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/wes/wesChutes")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_SORT_PLANS, data.data.sort_plans);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/wes/wesChutes/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          context.commit(SET_TABLE_SORTORDER, data.data.sortBy);
          context.commit(SET_TABLE_SORTBY, data.data.sortOrder);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/wes/wesChutes/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [CHUTES_ADD_REMOVE_CATEGORY_TO_SELECTED_LOCATION](state, payload) {
    const { selectedField, forAdding } = payload;
    const selectedLocationIndex = state.selectedLocationIndex;

    if (forAdding) {
      state.mappings[selectedLocationIndex].categories.push(selectedField);
      // state.mappings[selectedLocationIndex].categories.push({
      //   id: selectedField.index,
      //   // text: selectedField.text,
      //   // is_selected: true,
      // });
    } else {
      const foundIndex = state.mappings[
        selectedLocationIndex
      ].categories.findIndex((category) => category === selectedField);
      // ].categories.findIndex((category) => category.id === selectedField.index);

      if (foundIndex > -1) {
        state.mappings[selectedLocationIndex].categories.splice(foundIndex, 1);
      }
    }
  },
  [CHUTES_ADD_REMOVE_LOCATION_TO_SELECTED_SORT_PLAN](state, payload) {
    const { selectedField, forAdding } = payload;
    if (forAdding) {
      state.mappings.push({
        id: selectedField,
        // text: selectedField.text,
        categories: [],
        // is_selected: true,
      });
    } else {
      const foundIndex = state.mappings.findIndex(
        (map) => map.id === selectedField
      );

      if (foundIndex > -1) {
        state.mappings.splice(foundIndex, 1);
      }
    }
  },
  [CHUTES_SET_CATEGORIES](state, data) {
    state.categories = data.map((item) => ({
      ...item,
      is_selected: false,
    }));
  },
  [CHUTES_SET_LOCATIONS](state, data) {
    state.locations = data.locations.map((location) => ({
      ...location,
      is_selected: Boolean(
        data.mappings.find((item) => item.id === location.index)
      ),
    }));

    state.mappings = data.mappings.map((item) => ({
      ...item,
      // categories: item.categories.map((cat) => ({ id: cat })),
      // is_selected: false,
    }));
    state.selectedLocationIndex = -1;
  },
  [CHUTES_SET_SELECTED_LOCATION_INDEX](state, index) {
    state.selectedLocationIndex = index;
  },
  [CHUTES_SET_MAPPINGS](state, data) {
    state.mappings = data.map((item) => ({
      ...item,
      // is_selected: false,
    }));
  },
  [SET_SELECTED_SORT_PLAN](state, selectedSortPlan) {
    state.selectedSortPlan = selectedSortPlan;
  },
  [SET_SORT_PLANS](state, sortPlans) {
    state.sortPlans = sortPlans;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
