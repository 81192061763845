import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getPUTAWAYRULESTableStructure";
export const UPDATE_TABLE_DATA = "updatePUTAWAYRULESTableData";
export const EXPORT_TABLE_DATA = "exportPUTAWAYRULESTableData";
export const GET_DATA = "getPUTAWAYRULESGetData";
export const GET_TEMPLATE_DATA = "getPUTAWAYRULESTemplateData";
export const GET_DATA_FOR_EDIT = "getPUTAWAYRULESDataForEdit";

// mutation types
export const SET_TABLE_STRUCTURE = "setPUTAWAYRULESTableStructure";
export const SET_TABLE_DATA = "setPUTAWAYRULESTableData";
export const SET_TABLE_FILTER = "setPUTAWAYRULESTableFilter";
export const SET_TABLE_PAGE = "setPUTAWAYRULESTablePage";
export const SET_TABLE_PERPAGE = "setPUTAWAYRULESTablePerPage";
export const SET_TABLE_SORTBY = "setPUTAWAYRULESTableSortBy";
export const SET_TABLE_SORTORDER = "setPUTAWAYRULESTableSortOrder";
export const SET_EXPORT_LINK = "setPUTAWAYRULESExportLink";
export const SET_DATA = "setPUTAWAYRULESSetData";
export const SET_SUGGESTIONS = "setPUTAWAYRULESSuggestions";
export const EDIT_SUGGESTIONS = "editPUTAWAYRULESSuggestions";
export const REMOVE_SUGGESTIONS = "removePUTAWAYRULESSuggestions";
export const SET_RULES = "setPUTAWAYRules";
export const EDIT_RULES = "editPUTAWAYRules";
export const REMOVE_RULES = "removePUTAWAYRules";
export const SET_TEMPLATE_DATA = "setPUTAWAYRULESTemplateData";
export const SET_ADD_NEW_TEMPLATE_DATA = "setPUTAWAYRULESAddNewTemplateData";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  suggestions: [],
  rules: [],
  serverData: {},
  add_new_template_fields: {},
};

const getters = {
  getPUTAWAYRULESServerData(state) {
    return state.serverData;
  },
  getPUTAWAYRULESSuggestions(state) {
    return state.suggestions;
  },
  getPUTAWAYRULESRules(state) {
    return state.rules;
  },
  getPUTAWAYRULESTableProperties(state) {
    return state.table_structure.properties;
  },
  getPUTAWAYRULESTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getPUTAWAYRULESTableState(state) {
    return state.table_state;
  },
  getPUTAWAYRULESTableHeaders(state) {
    return state.table_structure.columns;
  },
  getPUTAWAYRULESTableFilters(state) {
    return state.table_structure.filters;
  },
  getPUTAWAYRULESTableData(state) {
    return state.table_data.data;
  },
  getPUTAWAYRULESExportUrl(state) {
    return state.export_link;
  },
  getPUTAWAYRULESAddNewTemplateData(state) {
    return state.add_new_template_fields;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_rules")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_rules/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_rules/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [GET_DATA](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_rules/create")
        .then(({ data }) => {
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [GET_TEMPLATE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/putaway_rule_based_templates/get_templates",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TEMPLATE_DATA, data);
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [GET_DATA_FOR_EDIT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_rules/edit", payload)
        .then(({ data }) => {
          context.commit(
            SET_SUGGESTIONS,
            data.checklist.checklist_instructions
          );
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_TEMPLATE_DATA](state, data) {
    const [key, value] = [Object.keys(data)[1], Object.values(data)[1]];
    state.serverData[key] = [...value];
  },

  [SET_DATA](state, data) {
    state.serverData = data;
  },
  [SET_SUGGESTIONS](state, data) {
    if (Array.isArray(data)) state.suggestions = data;
    else state.suggestions.push(data);
  },
  [EDIT_SUGGESTIONS](state, data) {
    state.suggestions.splice(data.lastElementIndex, 1, data.newItem);
  },
  [REMOVE_SUGGESTIONS](state, item) {
    const index = state.suggestions.findIndex((elem) => elem.id === item.id);
    if (index > -1) {
      state.suggestions.splice(index, 1);
    }
  },
  [SET_RULES](state, data) {
    if (Array.isArray(data)) state.rules = data;
    else state.rules.push(data);
  },
  [EDIT_RULES](state, data) {
    state.rules.splice(data.lastElementIndex, 1, data.newItem);
  },
  [REMOVE_RULES](state, item) {
    const index = state.rules.findIndex((elem) => elem.id === item.id);
    if (index > -1) {
      state.rules.splice(index, 1);
    }
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;

    state.table_structure.permissions.forEach((item) => {
      if (item.name === "export") item.name = "exp";
    });
    state.table_structure.permissions.push({
      name: "export_checklists",
    });
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
