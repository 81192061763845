// mutation types
export const SET_CURRENT_QUERY = "setCurrentQuery";
export const SET_CURRENT_QUERY_RULES = "setCurrentQueryRules";
export const SET_CURRENT_CONDITION = "setCurrentCondition";
export const SET_CURRENT_CONDITION_RULES = "setCurrentConditionRules";

const state = {
  currentQuery: null,
  currentCondition: null,
};

const getters = {
  getCurrentQuery(state) {
    return state.currentQuery;
  },
  getCurrentCondition(state) {
    return state.currentCondition;
  },
};

const mutations = {
  [SET_CURRENT_QUERY](state, data) {
    if (typeof data === "object") state.currentQuery.rules.push(data);
    state.currentQuery = data;
  },
  [SET_CURRENT_QUERY_RULES](state, data) {
    if (typeof data === "object") state.currentQuery.rules.push(data);
    state.currentQuery = data;
  },
  [SET_CURRENT_CONDITION](state, data) {
    if (typeof data === "object") state.currentCondition.rules.push(data);
    state.currentCondition = data;
  },
  [SET_CURRENT_CONDITION_RULES](state, data) {
    if (typeof data === "object") state.currentCondition.rules.push(data);
    state.currentCondition = data;
  },
};

export default {
  state,
  mutations,
  getters,
};
