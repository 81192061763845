import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "updateINBOUNDPACKAGINGTableStructure";
export const UPDATE_TABLE_DATA = "updateINBOUNDPACKAGINGTableData";
export const EXPORT_TABLE_DATA = "exportINBOUNDPACKAGINGTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setINBOUNDPACKAGINGTableStructure";
export const SET_TABLE_DATA = "setINBOUNDPACKAGINGTableData";
export const SET_TABLE_FILTER = "setINBOUNDPACKAGINGTableFilter";
export const SET_TABLE_PAGE = "setINBOUNDPACKAGINGTablePage";
export const SET_TABLE_PERPAGE = "setINBOUNDPACKAGINGTablePerPage";
export const SET_TABLE_SORTBY = "setINBOUNDPACKAGINGTableSortBy";
export const SET_TABLE_SORTORDER = "setINBOUNDPACKAGINGTableSortOrder";
export const SET_EXPORT_LINK = "setINBOUNDPACKAGINGExportLink";
export const SET_SUGGESTED_DETAILS = "setINBOUNDPACKAGINGSuggestedDetails";
export const SET_SCANNED_ITEMS = "setINBOUNDPACKAGINGScannedItems";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  suggestions: {},
  scanned_items: [],
};

const getters = {
  getINBOUNDPACKAGINGTableProperties(state) {
    return state.table_structure.properties;
  },
  getINBOUNDPACKAGINGTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getINBOUNDPACKAGINGTableState(state) {
    return state.table_state;
  },

  getINBOUNDPACKAGINGTableHeaders(state) {
    return state.table_structure.columns;
  },

  getINBOUNDPACKAGINGTableFilters(state) {
    return state.table_structure.filters;
  },

  getINBOUNDPACKAGINGTableData(state) {
    return state.table_data.data;
  },
  getINBOUNDPACKAGINGUploadType(state) {
    return state.table_structure.type;
  },
  getINBOUNDPACKAGINGExportUrl(state) {
    return state.export_link;
  },
  getINBOUNDPACKAGINGSuggestedDetails(state) {
    return state.suggestions;
  },
  getINBOUNDPACKAGINGScannedItems(state) {
    return state.scanned_items;
  },
  getINBOUNDPACKAGINGUsers(state) {
    return state.table_structure.users;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    // console.log(payload);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/custom/inbound_packaging")
        .then(({ data }) => {
          // console.log("suc", data);
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    // state.page_loading = true;
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post(
        "/warehouse_management/custom/inbound_packaging/datatable",
        payload
      )
        .then(({ data }) => {
          // console.log("data of datatable ", data);
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          // state.page_loading = false;
          setTimeout(() => {
            window.scrollTo(0, 0);
            context.commit(SET_PAGE_LOADING, false);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/custom/inbound_packaging/export",
        payload
      )
        .then(({ data }) => {
          // console.log("export data is ", data);
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_SUGGESTED_DETAILS](state, data) {
    if (data.operation === "spread") {
      state.suggestions = { ...state.suggestions, ...data.info };
    } else {
      state.suggestions = data.info;
    }
  },
  [SET_SCANNED_ITEMS](state, data) {
    if (data.operation === "spread") {
      state.scanned_items = { ...state.scanned_items, ...data.data };
    } else {
      state.scanned_items = data.data;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
