import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export const UPDATE_RETURN_INTEGRATION_STRUCTURE =
  "getRETURNINTEGRATIONStructure";
export const SET_RETURN_INTEGRATION_STRUCTURE = "setRETURNINTEGRATIONStructure";

const state = {
  page_stucture: {},
};

const getters = {
  getRETURNINTEGRATIONProperties(state) {
    return state.page_stucture;
  },
};

const actions = {
  [UPDATE_RETURN_INTEGRATION_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns_extensions")
        .then(({ data }) => {
          context.commit(SET_RETURN_INTEGRATION_STRUCTURE, data.data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_RETURN_INTEGRATION_STRUCTURE](state, data) {
    return (state.page_stucture = data);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
