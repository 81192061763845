import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getQCCHECKLISTSTableStructure";
export const UPDATE_TABLE_DATA = "updateQCCHECKLISTSTableData";
export const EXPORT_TABLE_DATA = "exportQCCHECKLISTSTableData";
export const GET_CLIENTS_AND_QC_CATEGORIES =
  "getQCCHECKLISTSClientsAndQCCategories";
export const GET_CHECKLIST_DATA_FOR_EDIT = "getQCCHECKLISTSDataForEdit";

// mutation types
export const SET_TABLE_STRUCTURE = "setQCCHECKLISTSTableStructure";
export const SET_TABLE_DATA = "setQCCHECKLISTSTableData";
export const SET_TABLE_FILTER = "setQCCHECKLISTSTableFilter";
export const SET_TABLE_PAGE = "setQCCHECKLISTSTablePage";
export const SET_TABLE_PERPAGE = "setQCCHECKLISTSTablePerPage";
export const SET_TABLE_SORTBY = "setQCCHECKLISTSTableSortBy";
export const SET_TABLE_SORTORDER = "setQCCHECKLISTSTableSortOrder";
export const SET_EXPORT_LINK = "setQCCHECKLISTSExportLink";
export const SET_CLIENTS = "setQCCHECKLISTSClients";
export const SET_QC_CATEGORIES = "setQCCHECKLISTSQCCategories";
export const SET_CHECKLIST_INSTRUCTIONS = "setQCCHECKLISTSInstructions";
export const EDIT_CHECKLIST_INSTRUCTIONS = "editQCCHECKLISTSInstructions";
export const REMOVE_CHECKLIST_INSTRUCTIONS = "removeQCCHECKLISTSInstructions";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  qcCategories: [],
  clients: [],
  checklistInstructions: [],
  status: [
    {
      index: 0,
      text: "Disabled",
    },
    {
      index: 1,
      text: "Enabled",
    },
  ],
};

const getters = {
  getQCCHECKLISTSClients(state) {
    return state.clients;
  },
  getQCCHECKLISTSQCCategories(state) {
    return state.qcCategories;
  },
  getQCCHECKLISTSInstructions(state) {
    return state.checklistInstructions;
  },
  getQCCHECKLISTSStatus(state) {
    return state.status;
  },
  getQCCHECKLISTSTableProperties(state) {
    return state.table_structure.properties;
  },
  getQCCHECKLISTSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getQCCHECKLISTSTableState(state) {
    return state.table_state;
  },

  getQCCHECKLISTSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getQCCHECKLISTSTableFilters(state) {
    return state.table_structure.filters;
  },

  getQCCHECKLISTSTableData(state) {
    return state.table_data.data;
  },

  getQCCHECKLISTSExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/qc_checklists")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post("/warehouse_management/qc_checklists/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/qc_checklists/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [GET_CLIENTS_AND_QC_CATEGORIES](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/qc_checklists/create")
        .then(({ data }) => {
          context.commit(SET_CLIENTS, data.clients);
          context.commit(SET_QC_CATEGORIES, data.qc_categories);
          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [GET_CHECKLIST_DATA_FOR_EDIT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/qc_checklists/edit", payload)
        .then(({ data }) => {
          context.commit(
            SET_CHECKLIST_INSTRUCTIONS,
            data.checklist.checklist_instructions
          );
          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_CHECKLIST_INSTRUCTIONS](state, data) {
    if (Array.isArray(data)) state.checklistInstructions = data;
    else state.checklistInstructions.push(data);
  },
  [EDIT_CHECKLIST_INSTRUCTIONS](state, data) {
    state.checklistInstructions.splice(data.lastElementIndex, 1, data.newItem);
  },
  [REMOVE_CHECKLIST_INSTRUCTIONS](state, item) {
    const index = state.checklistInstructions.findIndex(
      (elem) => elem.id === item.id
    );
    if (index > -1) {
      state.checklistInstructions.splice(index, 1);
    }
  },
  [SET_QC_CATEGORIES](state, categories) {
    state.qcCategories = categories;
  },
  [SET_CLIENTS](state, clients) {
    state.clients = clients;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;

    state.table_structure.permissions.forEach((item) => {
      if (item.name === "export") item.name = "exp";
    });
    state.table_structure.permissions.push({
      name: "export_checklists",
    });
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
