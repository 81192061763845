import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getSORTATIONSKUCASESTableStructure";
export const UPDATE_TABLE_DATA = "getSORTATIONSKUCASESTableData";
export const UPDATE_CONFIRM_ORDER_DATA = "getSORTATIONSKUCASESConfirmOrderData";

// mutation types
export const SET_TABLE_STRUCTURE = "setSORTATIONSKUCASESTableStructure";
export const SET_TABLE_DATA = "setSORTATIONSKUCASESTableData";
export const SET_TABLE_FILTER = "setSORTATIONSKUCASESTableFilter";
export const SET_TABLE_PAGE = "setSORTATIONSKUCASESTablePage";
export const SET_TABLE_PERPAGE = "setSORTATIONSKUCASESTablePerPage";
export const SET_TABLE_SORTBY = "setSORTATIONSKUCASESTableSortBy";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  confirm_order: {},
};

const getters = {
  /**
   * for table title, descripton
   * */
  getSORTATIONSKUCASESTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getSORTATIONSKUCASESTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getSORTATIONSKUCASESTableState(state) {
    return state.table_state;
  },
  getSORTATIONSKUCASESTableHeaders(state) {
    return state.table_structure.columns;
  },
  getSORTATIONSKUCASESTableFilters(state) {
    return state.table_structure.filters;
  },
  getSORTATIONSKUCASESTableData(state) {
    return state.table_data.data;
  },
  getSORTATIONSKUCASESExportUrl(state) {
    return state.export_link;
  },
  getSORTATIONSKUCASESConfirmOrderData(state) {
    return state.confirm_order;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    // console.log(payload);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/sortation_sku_cases")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/sortation_sku_cases/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  // [SET_TABLE_SORTORDER](state, sortOrder) {
  //   state.table_state.sortOrder = sortOrder;
  // },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },

  // [SET_EXPORT_LINK](state, link) {
  //   state.export_link = link;
  // },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
