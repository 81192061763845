import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const GET_DATA_SCAN_CONFIG_TEMPLATE = "GET_DATA_SCAN_CONFIG_TEMPLATE";
export const SCAN_CONFIG_TEMPLATE_CREATE_API =
  "callSCANCONFIGTEMPLATECreateAPI";

// mutation types
export const SET_TEMPLATE_FIELDS = "setSCANCONFIGTEMPLATETemplateFields";
export const SET_TEMPLATES = "setSCANCONFIGTEMPLATETemplates";
export const SET_FIELDS = "setSCANCONFIGTEMPLATEFields";
export const ADD_REMOVE_FIELD_TO_SELECTED_TEMPLATE =
  "addAndRemoveFieldToSelectedTemplate";
export const SET_SELECTED_TEMPLATE_DETAILS =
  "setSCANCONFIGTEMPLATESelectedTemplateDetails";
export const SELECT_ALL_FIELDS = "selectAllFields";
export const SET_CREATE_DIALOG_SERVER_DATA =
  "setCreateDialogServerDataForSCANCONFIGTEMPLATE";

const state = {
  scanning_configuration_template_fields: [],
  templates: [],
  fields: [],
  templateLabel: "Create a template",
  fieldLabel: "Select a template",
  actionLabel: "Select a field",
  selectedTemplateDetails: { showForm: false },
  serverData: {},
};

const getters = {
  getSCANCONFIGTEMPLATETemplates(state) {
    return state.templates;
  },
  getSCANCONFIGTEMPLATEFields(state) {
    return state.fields;
  },
  getSCANCONFIGTEMPLATETemplateLabel(state) {
    return state.templateLabel;
  },
  getSCANCONFIGTEMPLATEFieldLabel(state) {
    return state.fieldLabel;
  },
  getSCANCONFIGTEMPLATESelectedTemplateDetails(state) {
    return state.selectedTemplateDetails;
  },
  getSCANCONFIGTEMPLATEServerData(state) {
    return state.serverData;
  },
};

const actions = {
  [GET_DATA_SCAN_CONFIG_TEMPLATE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/custom/scanning_configuration_templates"
      )
        .then(({ data }) => {
          // console.log(data);
          context.commit(
            SET_TEMPLATES,
            data.data.scanning_configuration_templates
          );
          context.commit(
            SET_TEMPLATE_FIELDS,
            data.data.scanning_configuration_template_fields
          );
          context.commit(SET_FIELDS, { module_id: null });
          // context.commit(SET_ACTIONS, { field_id: null, actions: null });
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [SCAN_CONFIG_TEMPLATE_CREATE_API](context) {
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/custom/scanning_configuration_templates/create"
      )
        .then(({ data }) => {
          // console.log("create data", data);
          context.commit(SET_CREATE_DIALOG_SERVER_DATA, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_CREATE_DIALOG_SERVER_DATA](state, data) {
    state.serverData = data;
  },
  [SELECT_ALL_FIELDS](state, selectAll) {
    const { template_index } = state.selectedTemplateDetails;
    if (selectAll) {
      state.templates[template_index].fields = state.fields.map((field) => {
        field.is_selected = true;

        return {
          index: field.id,
          field_name: field.id,
          field_type: field.field_type,
          is_master_scan: field.is_allowed_for_master_scan ? false : null,
          is_required: 0,
          is_editable: 0,
          is_parseable: field.is_allowed_for_parsing ? false : null,
          is_persist_input_value: field.is_persist_input_value,
          default_value: field.is_default_value_allowed ? "" : null,
          display_order: null,
          field_alias: null,
        };
      });
    } else {
      state.fields.forEach((field) => {
        field.is_selected = false;
      });

      state.templates[template_index].fields.splice(0);
    }
  },

  [ADD_REMOVE_FIELD_TO_SELECTED_TEMPLATE](state, payload) {
    const { selectedField, forAdding } = payload;
    const { template_index } = state.selectedTemplateDetails;

    if (forAdding) {
      state.templates[template_index].fields.push({
        index: selectedField.id,
        field_name: selectedField.id,
        field_type: selectedField.field_type,
        is_master_scan: selectedField.is_allowed_for_master_scan ? false : null,
        is_required: 0,
        is_editable: 0,
        is_parseable: selectedField.is_allowed_for_parsing ? false : null,
        is_persist_input_value: selectedField.is_persist_input_value,
        default_value: selectedField.is_default_value_allowed ? "" : null,
        display_order: null,
        field_alias: null,
      });
    } else {
      const foundIndex = state.templates[template_index].fields.findIndex(
        (item) => item.field_name === selectedField.id
      );

      if (foundIndex > -1) {
        state.templates[template_index].fields.splice(foundIndex, 1);
      }
    }
  },
  [SET_SELECTED_TEMPLATE_DETAILS](state, obj) {
    state.selectedTemplateDetails = {
      ...state.selectedTemplateDetails,
      ...obj,
    };
  },
  [SET_TEMPLATE_FIELDS](state, data) {
    state.scanning_configuration_template_fields = [...data];
  },
  [SET_TEMPLATES](state, data) {
    state.templates = [...data];
    state.selectedTemplateDetails = { showForm: false };
  },
  [SET_FIELDS](state, payload) {
    if (payload.module_id) {
      state.fields = state.scanning_configuration_template_fields
        .find((item) => item.module === payload.module_id)
        .fields.map((field) => ({
          ...field,
          is_selected: Boolean(
            payload.fields.find((item) => item.field_name === field.id)
          ),
        }));
    } else state.fields = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
