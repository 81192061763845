export const SET_FILTERS_DATA = "setFiltersData";
export const SET_SKIP_ADDING_WAREHOUSE = "setSkipAddingWarehouse";

const state = {
  filters_data: {},
  skipAddingWarehouse: false,
};

const getters = {
  getFiltersData(state) {
    return state.filters_data;
  },
  getSkipAddingWarehouse(state) {
    return state.skipAddingWarehouse;
  },
};

const mutations = {
  [SET_SKIP_ADDING_WAREHOUSE](state, skipAddingWarehouse) {
    state.skipAddingWarehouse = skipAddingWarehouse;
  },
  [SET_FILTERS_DATA](state, filters_data) {
    state.filters_data = filters_data;
  },
};

export default {
  state,
  mutations,
  getters,
};
