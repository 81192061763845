import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_CUSTOMER_DATA = "getSTANDARDPACKAGEMOVEMENTCustomerData";
export const UPDATE_SCANNING_FIELDS =
  "getSTANDARDPACKAGEMOVEMENTScanningFields";

// mutations types
export const SET_CUSTOMER_DATA = "setSTANDARDPACKAGEMOVEMENTCustomerData";
export const SET_SCANNING_FIELDS = "setSTANDARDPACKAGEMOVEMENTScanningFields";

const state = {
  customer_data: {},
};

const getters = {
  getSTANDARDPACKAGEMOVEMENTCustomersData(state) {
    return state.customer_data;
  },
};

const actions = {
  [UPDATE_CUSTOMER_DATA](context, payload) {
    return ApiService.post(
      "warehouse_management/custom/package_movements/create",
      payload
    )
      .then(({ data }) => {
        context.commit(SET_CUSTOMER_DATA, data);
      })
      .finally(() => {
        context.commit(SET_PAGE_LOADING, false);
      });
  },
};

const mutations = {
  [SET_CUSTOMER_DATA](state, customer_data) {
    state.customer_data = customer_data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
