// mutation types
export const SET_PREVIOUS_SELECTED_MENU = "setPreviousSelectedMenu";

const state = {
  previousSelectedMenu: null,
};

const getters = {
  getPreviousSelectedMenu(state) {
    return state.previousSelectedMenu;
  },
};

const mutations = {
  [SET_PREVIOUS_SELECTED_MENU](state, menuName) {
    state.previousSelectedMenu = menuName;
  },
};

export default {
  state,
  mutations,
  getters,
};
