import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const GET_COUNTRIES = "getSHIPPINGCOUNTRIESCountries";
export const GET_STATES = "getSHIPPINGCOUNTRIESStates";
export const GET_CITIES = "getSHIPPINGCOUNTRIESCities";
export const GET_AREAS = "getSHIPPINGCOUNTRIESAreas";
export const EXPORT_TABLE_DATA = "exportSHIPPINGCOUNTRIESTableData";
export const GET_CURRENCIES = "getSHIPPINGCOUNTRIESCurrenciesData";

// mutation types
export const SET_COUNTRIES = "setSHIPPINGCOUNTRIESCountries";
export const SET_STATES = "setSHIPPINGCOUNTRIESStates";
export const SET_CITIES = "setSHIPPINGCOUNTRIESCities";
export const SET_AREAS = "setSHIPPINGCOUNTRIESAreas";
export const SET_ORGANIZATION_ID = "setSHIPPINGCOUNTRIESOrganizationId";
export const SET_STATES_LENGTH = "setSHIPPINGCOUNTRIESStatesLength";
export const SET_CITIES_LENGTH = "setSHIPPINGCOUNTRIESCitiesLength";
export const SET_AREAS_LENGTH = "setSHIPPINGCOUNTRIESAreasLength";
export const SET_STATES_LABEL = "setSHIPPINGCOUNTRIESStatesLabel";
export const SET_CITIES_LABEL = "setSHIPPINGCOUNTRIESCitiesLabel";
export const SET_AREAS_LABEL = "setSHIPPINGCOUNTRIESAreasLabel";
export const SET_EXPORT_LINK = "setSHIPPINGCOUNTRIESExportLink";
export const SET_CURRENCIES = "setSHIPPINGCOUNTRIESCurrencies";
export const SET_ORGANIZATIONS = "setSHIPPINGCOUNTRIESOrganizations";
export const SET_SELECTED_COUNTRY_ID = "setSHIPPINGCOUNTRIESSelectedCountryId";
export const SET_SELECTED_STATE_ID = "setSHIPPINGCOUNTRIESSelectedStateId";
export const SET_SELECTED_CITY_ID = "setSHIPPINGCOUNTRIESSelectedCityId";

const state = {
  export_link: null,
  countries: [],
  states: [],
  cities: [],
  areas: [],
  currencies: [],
  stateLabel: "Select a country",
  cityLabel: "Select a state",
  areaLabel: "Select a city",
  selectedCountryId: null,
  selectedStateId: null,
  selectedCityId: null,
  organizations: null,
  organization_id: null,
};

const getters = {
  getSHIPPINGCOUNTRIESCountriesData(state) {
    return state.countries;
  },

  getSHIPPINGCOUNTRIESStatesData(state) {
    return state.states;
  },

  getSHIPPINGCOUNTRIESCitiesData(state) {
    return state.cities;
  },

  getSHIPPINGCOUNTRIESAreasData(state) {
    return state.areas;
  },

  getSHIPPINGCOUNTRIESStatesLabel(state) {
    return state.stateLabel;
  },

  getSHIPPINGCOUNTRIESCitiesLabel(state) {
    return state.cityLabel;
  },

  getSHIPPINGCOUNTRIESAreasLabel(state) {
    return state.areaLabel;
  },

  getSHIPPINGCOUNTRIESExportUrl(state) {
    return state.export_link;
  },

  getSHIPPINGCOUNTRIESCurrencies(state) {
    return state.currencies;
  },

  getSHIPPINGCOUNTRIESOrganizations(state) {
    return state?.organizations;
  },

  getSHIPPINGCOUNTRIESSelectedCountryId(state) {
    return state.selectedCountryId;
  },
  getSHIPPINGCOUNTRIESSelectedStateId(state) {
    return state.selectedStateId;
  },
  getSHIPPINGCOUNTRIESSelectedCityId(state) {
    return state.selectedCityId;
  },

  getSHIPPINGCOUNTRIESOrganizationId(state) {
    return state.organization_id;
  },
};

const actions = {
  [GET_COUNTRIES](context) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/countries")
        .then(({ data }) => {
          context.commit(SET_COUNTRIES, data.values);
          context.commit(SET_ORGANIZATIONS, data.organizations);
          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_STATES](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/countries", payload)
        .then(({ data }) => {
          context.commit(SET_STATES_LENGTH);
          context.commit(SET_CITIES_LENGTH);
          context.commit(SET_AREAS_LENGTH);

          context.commit(SET_CITIES_LABEL, "Select a state");
          context.commit(SET_AREAS_LABEL, "Select a city");

          if (data.values.length === 0)
            context.commit(SET_STATES_LABEL, "State not found");
          else context.commit(SET_STATES, data.values);

          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_CITIES](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/countries", payload)
        .then(({ data }) => {
          context.commit(SET_CITIES_LENGTH);
          context.commit(SET_AREAS_LENGTH);

          context.commit(SET_AREAS_LABEL, "Select a city");

          if (data.values.length === 0)
            context.commit(SET_CITIES_LABEL, "City not found");
          else if (data.values.length === 1) {
            context.commit(SET_CITIES, data.values);
            context.dispatch(GET_AREAS, {
              city_id: data.values[0].id,
              type: "area",
            });
          } else context.commit(SET_CITIES, data.values);

          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_AREAS](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/shipping/countries", payload)
        .then(({ data }) => {
          context.commit(SET_AREAS_LENGTH);
          if (data.values.length === 0)
            context.commit(SET_AREAS_LABEL, "Area not found");
          else context.commit(SET_AREAS, data.values);
          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_CURRENCIES](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/countries/create")
        .then(({ data }) => {
          context.commit(SET_CURRENCIES, data.currencies);

          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_ORGANIZATION_ID](state, organization_id) {
    state.organization_id = organization_id;
  },
  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [SET_STATES](state, states) {
    state.states = states;
  },
  [SET_CITIES](state, cities) {
    state.cities = cities;
  },
  [SET_AREAS](state, areas) {
    state.areas = areas;
  },
  [SET_STATES_LENGTH](state) {
    state.states.splice(0);
  },
  [SET_CITIES_LENGTH](state) {
    state.cities.splice(0);
  },
  [SET_AREAS_LENGTH](state) {
    state.areas.splice(0);
  },
  [SET_STATES_LABEL](state, label) {
    state.stateLabel = label;
  },
  [SET_CITIES_LABEL](state, label) {
    state.cityLabel = label;
  },
  [SET_AREAS_LABEL](state, label) {
    state.areaLabel = label;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_CURRENCIES](state, currencies) {
    state.currencies = currencies;
  },
  [SET_ORGANIZATIONS](state, organizations) {
    state.organizations = organizations;
  },
  [SET_SELECTED_COUNTRY_ID](state, id) {
    state.selectedCountryId = id;
  },
  [SET_SELECTED_STATE_ID](state, id) {
    state.selectedStateId = id;
  },
  [SET_SELECTED_CITY_ID](state, id) {
    state.selectedCityId = id;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
