import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getSUBSCRIPTIONBUYERSTableStructure";
export const UPDATE_TABLE_DATA = "updateSUBSCRIPTIONBUYERSTableData";
// export const EXPORT_TABLE_DATA = "exportSUBSCRIPTIONBUYERSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setSUBSCRIPTIONBUYERSTableStructure";
export const SET_TABLE_DATA = "setSUBSCRIPTIONBUYERSTableData";
export const SET_TABLE_FILTER = "setSUBSCRIPTIONBUYERSTableFilter";
export const SET_TABLE_PAGE = "setSUBSCRIPTIONBUYERSTablePage";
export const SET_TABLE_PER_PAGE = "setSUBSCRIPTIONBUYERSTablePerPage";
export const SET_TABLE_SORT_BY = "setSUBSCRIPTIONBUYERSTableSortBy";
// export const SET_EXPORT_LINK = "setSUBSCRIPTIONBUYERSExportLink";
export const SET_COUNTRIES = "setSUBSCRIPTIONBUYERSCountries";
export const SET_CURRENCIES = "setSUBSCRIPTIONBUYERSCurrencies";

const state = {
  // export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  currencies: [],
  countries: [],
};

const getters = {
  getSUBSCRIPTIONBUYERSTableProperties(state) {
    return state.table_structure.properties;
  },
  getSUBSCRIPTIONBUYERSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getSUBSCRIPTIONBUYERSCountries(state) {
    return state.countries;
  },
  getSUBSCRIPTIONBUYERSCurrencies(state) {
    return state.currencies;
  },
  getSUBSCRIPTIONBUYERSTableState(state) {
    return state.table_state;
  },
  getSUBSCRIPTIONBUYERSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getSUBSCRIPTIONBUYERSTableFilters(state) {
    return state.table_structure.filters;
  },

  getSUBSCRIPTIONBUYERSTableData(state) {
    return state.table_data.data;
  },

  // getSUBSCRIPTIONBUYERSExportUrl(state) {
  //   return state.export_link;
  // },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/subscription_buyers")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .finally((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/order_management/subscription_buyers/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .finally((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  // [EXPORT_TABLE_DATA](context, payload) {
  //   context.commit(SET_PAGE_LOADING, true);
  //   return new Promise((resolve) => {
  //     ApiService.post("/order_management/subscription_buyers/export", payload)
  //       .then(({ data }) => {
  //         context.commit(SET_EXPORT_LINK, data.url);
  //         resolve(data);
  //       })
  //       .catch((data) => {
  //         context.commit(SET_PAGE_LOADING, false);
  //         resolve(data);
  //       });
  //   });
  // },
};

const mutations = {
  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [SET_CURRENCIES](state, currencies) {
    state.currencies = currencies;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PER_PAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORT_BY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  // [SET_EXPORT_LINK](state, link) {
  //   state.export_link = link;
  // },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
