import i18n from "@/core/plugins/vue-i18n";

const i18nService = {
  init() {
    this.loadLaguagesFromLocalStorage;
  },
  defaultLanguage: "en",

  /**
   * store translation key value pears
   */
  loadedLanguageTranslations: [],

  /**
   * @type {[{index: string, text: string, flag: stirng, rtl: boolean}]}
   */
  languageslist: [],
  numberFormats: {
    en: {
      currency: { style: "currency", currency: "USD" },
    },
    ar: {
      currency: { style: "currency", currency: "USD", currencyDisplay: "code" },
    },
  },
  dateTimeFormats: {
    en: {
      short: { year: "numeric", month: "short", day: "numeric" },
    },
    ar: {
      short: { year: "numeric", month: "long", day: "numeric" },
    },
  },

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang = this.getActiveLanguage()) {
    localStorage.setItem("language", lang);
    this.loadLocaleMessagesAsync(lang);
    this.setDocumentDirectionPerLocale(lang);
    this.setDocumentLang(lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  },

  // get translation value
  getTranslationValue(value) {
    let trans = this.loadLanguageTranslationsFromLocalStorage(
      this.getActiveLanguage()
    );
    let trans_value = `trans ${value}`;
    Object.entries(trans).find((val_arr) => {
      if (val_arr[0] === value) {
        trans_value = val_arr[1];
      }
    });
    return trans_value;
  },

  isRTL() {
    // console.log(this.getActiveLanguage(), this.languageslist);
    if (this.languageslist.length < 1) {
      this.loadLaguagesFromLocalStorage().then(() => {
        return this.languageslist.find(
          (lang) => lang.abbreviation == this.getActiveLanguage()
        )["rtl"];
      });
    } else {
      return this.languageslist.find(
        (lang) => lang.abbreviation == this.getActiveLanguage()
      )["rtl"];
    }
  },

  async fetchLanguageDataFromServer() {
    await fetch(process.env.VUE_APP_BASE_URL + "/v1/values/translations", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem(
          "translation_data",
          JSON.stringify(data.translations)
        );
        localStorage.setItem("languages_list", JSON.stringify(data.languages));
        localStorage.setItem(
          "last_updated_time",
          JSON.stringify(data.last_updated_time)
        );
        this.languageslist = data.languages;
        this.loadedLanguageTranslations = [];
      });
  },

  async loadLaguagesFromLocalStorage() {
    if (!localStorage.getItem("languages_list")) {
      this.fetchLanguageDataFromServer().then(() => {
        this.languageslist = JSON.parse(localStorage.getItem("languages_list"));
        return Promise.resolve(this.languageslist);
      });
    } else {
      this.languageslist = JSON.parse(localStorage.getItem("languages_list"));
      return Promise.resolve(this.languageslist);
    }
  },

  loadLanguageTranslationsFromLocalStorage(locale = this.getActiveLanguage()) {
    return JSON.parse(localStorage.getItem("translation_data"))[`${locale}`];
  },

  loadLocaleMessagesAsync(locale = this.getActiveLanguage()) {
    // check is languages get form user data
    if (this.languageslist.length == 0) {
      this.loadLaguagesFromLocalStorage();
    }

    if (this.loadedLanguageTranslations.length > 0 && i18n.locale === locale) {
      return Promise.resolve(locale);
    }
    // If the language was already loaded
    if (this.loadedLanguageTranslations.includes(locale)) {
      i18n.locale = locale;
      return Promise.resolve(locale);
    }
    // If the language hasn't been loaded yet
    // return import(
    //   /* webpackChunkName: "locale-[request]" */ `@/own/locales/${locale}.json`
    // ).then((messages) => {
    //   i18n.setLocaleMessage(locale, messages.default);

    //   this.loadedLanguageTranslations.push(locale);

    //   i18n.locale = locale;

    //   return Promise.resolve(locale);
    // });

    // TODO: fetch from backend
    // get translations form backend
    if (!localStorage.getItem("languages_list")) {
      return this.fetchLanguageDataFromServer().then(() => {
        i18n.setLocaleMessage(
          locale,
          this.loadLanguageTranslationsFromLocalStorage(locale)
        );
        this.loadedLanguageTranslations.push(locale);
        i18n.locale = locale;
        return Promise.resolve(locale);
      });
    } else {
      setTimeout(() => {
        i18n.setLocaleMessage(
          locale,
          this.loadLanguageTranslationsFromLocalStorage(locale)
        );
        this.loadedLanguageTranslations.push(locale);
        i18n.locale = locale;
        return Promise.resolve(locale);
      }, 0);
    }
  },
  setDocumentLang(lang = this.getActiveLanguage()) {
    document.documentElement.lang = lang;
  },
  setDocumentDirectionPerLocale() {
    document.dir = this.isRTL() ? "rtl" : "ltr";
    // console.log(document.dir);
    document.documentElement.style.setProperty(
      "--main-font",
      this.isRTL() ? "'Almarai', 'Open Sans'" : "Poppins, Helvetica"
    );
    // console.log(document.documentElement.style.getPropertyValue("--main-font"));
  },
};
i18nService.loadLocaleMessagesAsync();
setTimeout(() => {
  i18nService.setDocumentDirectionPerLocale();
  i18nService.setDocumentLang();
}, 1500);

export default i18nService;
