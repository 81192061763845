export const getLocalStorageItem = (key) => {
  return window.localStorage.getItem(key);
};

export const saveLocalStorageItem = (key, data) => {
  window.localStorage.setItem(key, data);
};

export const destroyLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
};

// export default {
//   getLocalStorageItem,
//   saveLocalStorageItem,
//   destroyLocalStorageItem,
// };
