import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getPUTAWAYTASKSTableStructure";
export const UPDATE_TABLE_DATA = "updatePUTAWAYTASKSTableData";
export const STORE_SCANNED_PUTAWAY = "storeScannedPutaway";
export const GET_PUTAWAY_TASK_DATA = "getPUTAWAYTASKData";
export const NAVIGATE_PUTAWAY_ITEM = "navigatePUTAWAYTASKSTableItem";
export const EXPORT_TABLE_DATA = "exportPUTAWAYTASKSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setPUTAWAYASKSTableStructure";
export const SET_TABLE_DATA = "setPUTAWAYTASKSTableData";
export const SET_TABLE_FILTER = "setPUTAWAYTASKSTableFilter";
export const SET_TABLE_PERPAGE = "setPUTAWAYASKSTablePerPage";
export const SET_TABLE_SORTBY = "setPUTAWAYTASKSTableSortBy";
export const SET_TABLE_PAGE = "setPAYMENTSTablePage";
export const SET_PUTAWAY_TASK_DATA = "setPUTAWAYTASKData";
export const SET_PUTAWAY_ITEM_FOR_ACTION = "setPUTAWAYITEMForAction";
export const SET_PUTAWAY_MODAL_TABLE_DATA = "setPUTAWAYModalTableData";
export const ADJUST_PUTAWAY_MODAL_TABLE_DATA = "adjustPUTAWAYModalTableData";
export const SET_PUTAWAY_MODAL_EMPTY = "setPUTAWAYModalEmpty";
export const ADJUST_PUTAWAY_TASK_DATA = "adjustPUTAWAYTASKData";
export const SET_SCANNING_ACTION_TYPE = "setScanningActionType";
export const SET_PUTAWAY_FETCH_SUCCEED_STATUS = "setPutawayFetchSucceedStatus";
export const SET_EXPORT_LINK = "setPUTAWAYASKSExportLink";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  suggestions: [],
  rules: [],
  serverData: {},
  putaway_task: {},
  putaway_for_action_item_index: 0,
  putaway_modal_data: [],
  tdata: {},
  scanning_action_type: "solo",
  putaway_fetch_succeed: false,
};

const getters = {
  getPUTAWAYTASKSTableProperties(state) {
    return state.table_structure.properties;
  },
  getPUTAWAYTASKSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getPUTAWAYTASKSTableState(state) {
    return state.table_state;
  },
  getPUTAWAYTASKSTableHeaders(state) {
    return state.table_structure.columns;
  },
  getPUTAWAYTASKSTableFilters(state) {
    return state.table_structure.filters;
  },
  getPUTAWAYTASKSPermissions(state) {
    return state.table_structure.permissions;
  },
  getPUTAWAYTASKSTableData(state) {
    return state.table_data.data;
  },
  getPUTAWAYTASKItemData(state) {
    return state.putaway_task;
  },
  getPUTAWAYITEMForAction(state) {
    return state.tdata[state.putaway_for_action_item_index];
  },
  getPUTAWAYModalTableData(state) {
    return state.putaway_modal_data;
  },
  getPUTAWAYScanningActionType(state) {
    return state.scanning_action_type;
  },
  getPUTAWAYFetchSucceedStatus(state) {
    return state.putaway_fetch_succeed;
  },
  getPUTAWAYTASKSExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post(`/warehouse_management/putaway_tasks`)
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          // context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          // context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(`/warehouse_management/putaway_tasks/datatable`, payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [STORE_SCANNED_PUTAWAY](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(`/warehouse_management/putaway_tasks/update`, payload)
        .then(() => {
          resolve();
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [GET_PUTAWAY_TASK_DATA](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(`/warehouse_management/putaway_tasks/show`, {
        ...payload,
        warehouse_id: context.getters.getSelectedWarehouse,
      })
        .then(({ data }) => {
          context.commit(SET_PUTAWAY_TASK_DATA, data);
          context.commit(SET_PUTAWAY_FETCH_SUCCEED_STATUS, true);
          resolve();
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_tasks/export", payload)
        .then(({ data }) => {
          // console.log("export data is ", data);
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  // [NAVIGATE_PUTAWAY_ITEM](context, currentItem) {},
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },

  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
    state.tdata = table_data.data.data;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_PUTAWAY_TASK_DATA](state, data) {
    state.putaway_task = data;
  },
  [SET_PUTAWAY_ITEM_FOR_ACTION](state, index) {
    state.putaway_for_action_item_index = index;
  },
  [SET_PUTAWAY_MODAL_TABLE_DATA](state, putaway) {
    state.putaway_modal_data.push(putaway);
  },
  [ADJUST_PUTAWAY_MODAL_TABLE_DATA](state, payload) {
    const prevQty = Number(state.putaway_modal_data[payload.index].qty);
    const newQty = Number(payload.payload.qty);
    state.putaway_modal_data[payload.index].qty = prevQty + newQty;
  },
  [SET_PUTAWAY_MODAL_EMPTY](state) {
    state.putaway_modal_data = [];
  },
  [ADJUST_PUTAWAY_TASK_DATA](state, quantity) {
    const putaway_task = state.putaway_task.putaway_task;
    putaway_task.expected_qty -= quantity;
    state.putaway_task = {
      ...state.putaway_task,
      putaway_task,
    };
  },
  [SET_SCANNING_ACTION_TYPE](state, type) {
    state.scanning_action_type = type;
  },
  [SET_PUTAWAY_FETCH_SUCCEED_STATUS](state, status) {
    state.putaway_fetch_succeed = status;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
