import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "updateSTANDARDRECEIVINGTableStructure";
export const UPDATE_TABLE_DATA = "updateSTANDARDRECEIVINGTableData";
export const EXPORT_TABLE_DATA = "exportSTANDARDRECEIVINGTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setSTANDARDRECEIVINGTableStructure";
export const SET_TABLE_DATA = "setSTANDARDRECEIVINGTableData";
export const SET_TABLE_FILTER = "setSTANDARDRECEIVINGTableFilter";
export const SET_TABLE_PAGE = "setSTANDARDRECEIVINGTablePage";
export const SET_TABLE_PERPAGE = "setSTANDARDRECEIVINGTablePerPage";
export const SET_TABLE_SORTBY = "setSTANDARDRECEIVINGTableSortBy";
export const SET_TABLE_SORTORDER = "setSTANDARDRECEIVINGTableSortOrder";
export const SET_EXPORT_LINK = "setSTANDARDRECEIVINGExportLink";
export const SET_SUGGESTED_DETAILS = "setSTANDARDRECEIVINGSuggestedDetails";
export const SET_SCANNED_ITEMS = "setSTANDARDRECEIVINGScannedItems";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  suggestions: {},
  scanned_items: [],
};

const getters = {
  getSTANDARDRECEIVINGTableProperties(state) {
    return state.table_structure.properties;
  },
  getSTANDARDRECEIVINGTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getSTANDARDRECEIVINGTableState(state) {
    return state.table_state;
  },

  getSTANDARDRECEIVINGTableHeaders(state) {
    return state.table_structure.columns;
  },

  getSTANDARDRECEIVINGTableFilters(state) {
    return state.table_structure.filters;
  },

  getSTANDARDRECEIVINGTableData(state) {
    return state.table_data.data;
  },
  getSTANDARDRECEIVINGUploadType(state) {
    return state.table_structure.type;
  },
  getSTANDARDRECEIVINGExportUrl(state) {
    return state.export_link;
  },
  getSTANDARDRECEIVINGSuggestedDetails(state) {
    return state.suggestions;
  },
  getSTANDARDRECEIVINGScannedItems(state) {
    return state.scanned_items;
  },
  getSTANDARDRECEIVINGSUsers(state) {
    return state.table_structure.users;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    // console.log(payload);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/custom/standard_receiving")
        .then(({ data }) => {
          // console.log("suc", data);
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    // state.page_loading = true;
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post(
        "/warehouse_management/custom/standard_receiving/datatable",
        payload
      )
        .then(({ data }) => {
          // console.log("data of datatable ", data);
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          // state.page_loading = false;
          setTimeout(() => {
            window.scrollTo(0, 0);
            context.commit(SET_PAGE_LOADING, false);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/organization/reason_codes/export", payload)
        .then(({ data }) => {
          // console.log("export data is ", data);
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_SUGGESTED_DETAILS](state, data) {
    if (data.operation === "spread") {
      state.suggestions = { ...state.suggestions, ...data.info };
    } else {
      state.suggestions = data.info;
    }
  },
  [SET_SCANNED_ITEMS](state, data) {
    if (data.operation === "spread") {
      state.scanned_items = { ...state.scanned_items, ...data.data };
    } else {
      state.scanned_items = data.data;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
