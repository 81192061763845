import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "getSHIPPINGCUSTOMTableStructure";
export const UPDATE_TABLE_DATA = "getSHIPPINGCUSTOMTableData";
export const EXPORT_TABLE_DATA = "getSHIPPINGCUSTOMEXPORTTABLEDATA";

// mutation types
export const SET_TABLE_STRUCTURE = "setSHIPPINGCUSTOMTableStructure";
export const SET_TABLE_DATA = "setSHIPPINGCUSTOMTableData";
export const SET_TABLE_FILTER = "setSHIPPINGCUSTOMTableFilter";
export const SET_TABLE_PAGE = "setSHIPPINGCUSTOMTablePage";
export const SET_TABLE_PERPAGE = "setSHIPPINGCUSTOMTablePerPage";
export const SET_TABLE_SORTBY = "setSHIPPINGCUSTOMTableSortBy";
export const SET_TABLE_SORTORDER = "setSHIPPINGCUSTOMTableSortOrder";
export const SET_FILTERS_DATA = "setSHIPPINGCUSTOMFiltersData";
export const SET_EXPORT_LINK = "setSHIPPINGCUSTOMExportLink";

const state = {
  table_structure: {},
  create_data: {},
  table_data: {},
  filters_data: [],
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getSHIPPINGCUSTOMTableState(state) {
    return state.table_state;
  },

  getSHIPPINGCUSTOMTableHeaders(state) {
    return state.table_structure.columns;
  },

  getSHIPPINGCUSTOMTableFilters(state) {
    return state.table_structure.filters;
  },

  getSHIPPINGCUSTOMTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getSHIPPINGCUSTOMTableData(state) {
    return state.table_data.data;
  },

  getSHIPPINGCUSTOMCreateData(state) {
    return state.create_data;
  },

  getSHIPPINGCUSTOMTableProperties(state) {
    return state.table_structure.properties;
  },

  getSHIPPINGCUSTOMExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context, integration_id) {
    return new Promise((resolve) => {
      ApiService.post("validations/shippingCustomFields", {
        integration_id: integration_id,
      })
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, integration_id, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/validations/shippingCustomFields/datatable", {
        ...payload,
        integration_id: integration_id,
      })
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/validations/shippingCustomFields/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_FILTERS_DATA](state, filter_data) {
    state.filters_data = filter_data;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
